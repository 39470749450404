import React from 'react';
import Article from 'components/help/helpArticle';

const Content = () => {
  return (
    <div>
      <p>
        It sometimes happens that the recording you are watching does not look like it should.
        Missing images, broken fonts or CSS in most cases indicates that LiveSession didn’t or
        doesn’t have access to resources hosted on your server.
      </p>

      <p>
        During the session capture, LiveSession makes a copy of CSS and Fonts files to recreate the
        playback in the future. We don’t copy images though. If access to any of your website’s
        files requires authentication (i.e., providing credentials), it may affect how playback is
        recreated. To put it simply: it will break the recording you see on our platform. This also
        applies if access to images was restricted after the session was recorded.
      </p>

      <p>
        LiveSession handles HTTPS connections to the external images and uses cached style sheets to
        recreate the website’s layout. It may happen that the recording will work for some users
        (i.e., if you’re using a VPN connection that gives you access to the files hosted on your
        company’s internal server), but not for others (your contractors who don’t have access to
        the company’s VPN).
      </p>
    </div>
  );
};

const related = [
  {
    link: '/help/how-livesession-script-works/',
    title: 'How LiveSession tracking script works',
    description: 'Understand what our tracking code tracks',
  },
];

export const frontmatter = {
  metaTitle: 'Why is my recording broken?',
  metaDescription:
    'Here are the most common reasons that may result in a broken recording and what to do to resolve them. See what you can do to get more accurate recordings.',
  canonical: '/help/why-is-my-recording-broken/',
};

const Wrapped = Article(Content);

export default () => (
  <Wrapped related={related} section="Product" title={frontmatter.metaTitle} {...frontmatter} />
);
